// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-project-corona-js": () => import("./../../../src/pages/project/corona.js" /* webpackChunkName: "component---src-pages-project-corona-js" */),
  "component---src-pages-project-facebook-js": () => import("./../../../src/pages/project/facebook.js" /* webpackChunkName: "component---src-pages-project-facebook-js" */),
  "component---src-pages-project-google-js": () => import("./../../../src/pages/project/google.js" /* webpackChunkName: "component---src-pages-project-google-js" */),
  "component---src-pages-project-halisi-js": () => import("./../../../src/pages/project/halisi.js" /* webpackChunkName: "component---src-pages-project-halisi-js" */),
  "component---src-pages-project-mixamigo-js": () => import("./../../../src/pages/project/mixamigo.js" /* webpackChunkName: "component---src-pages-project-mixamigo-js" */),
  "component---src-pages-project-vaccine-js": () => import("./../../../src/pages/project/vaccine.js" /* webpackChunkName: "component---src-pages-project-vaccine-js" */)
}

